$(function(){

    var root_html = $("#root_reply").html();
    if(!root_html){
        return;
    }

    function comment_reply(e){
        e.preventDefault();

        var $this = $(this);
        var $parent = $this.closest(".scomment");
        //var $parent = $($this.parents(".scomment"));
        var parnet_id = $parent.data("comment")
        var toggle = $parent.data("open")
        if(toggle=="true"){
            $parent.find(".replyzone").html("");
            $this.text("Reply");
        }else{
            $parent.data("open","true");
            $parent.find(".replyzone").html(root_html);
            $parent.find(".replyzone input[name='social_comment[parent_comment_id]']").val(parnet_id);
            $this.text("Cancel");
        }

    }

    $(".btn-comment-reply").on("click",comment_reply)

});