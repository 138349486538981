
$(function() { // :)

    console.log("grid!")

    var filterUrl = location.pathname;

    $(".perpage").on('change',function(e){
        $t = $(this)
        //console.log("per_page: " + $t.val() )


        var url = filterUrl;
        var params =  {}

        if(location.search){
            var xparams = location.search.split("?")[1].split("&");

            _.map(xparams,function(x){
                var parts = x.split("=");
                params[decodeURIComponent(parts[0])] = parts[1]
            })

        }

        if(params["page"]){
            delete(params["page"])
        }
        if(params["contacts_page"]){
            delete(params["contacts_page"])
        }

        params["per_page"] = $t.val()



        var urlParms = decodeURIComponent($.param(params))//.replace("%5B", "[").replace("%5D", "]");

        url += "?" + urlParms

        //console.log(params,url)

        window.location = url


    })


    function gridFilterChange(e){
        $t = $(e.target)
        filterKey = $t.attr("name");
        filterVal = $t.val()
        //console.log("change",filterKey,filterVal)

        searchFilter(filterKey,filterVal);
    }

    function inputKeypress(e){
        $t = $(e.target)
        if ( e.which == 13 ) {
            e.preventDefault();

            filterKey = $t.attr("name");
            filterVal = $t.val()
            //console.log("inputKeypress",filterKey,filterVal)

            searchFilter(filterKey,filterVal);

        }
    }

    function searchFilter(filterKey,filterVal){
        var url = filterUrl;



        var params =  {}

        if(location.search){
            var xparams = location.search.split("?")[1].split("&");

            _.map(xparams,function(x){
                var parts = x.split("=");
                params[decodeURIComponent(parts[0])] = parts[1]
            })

        }

        if(filterVal){
            params[filterKey] = encodeURIComponent(filterVal)
        }else{
            delete(params[filterKey])
        }

        if(params["page"]){
            delete(params["page"])
        }
        if(params["contacts_page"]){
            delete(params["contacts_page"])
        }




        var urlParms = decodeURIComponent($.param(params))//.replace("%5B", "[").replace("%5D", "]");

        url += "?" + urlParms

        //console.log(params,url)

        window.location = url


    }




    function formatApiUser (user) {
        if (user.loading) return user.text;

        return user.html;
    }

    function formatApiUserSelection (user) {
        //console.log("gridfilter.js formatApiUserSelection",user)

        if(user.text) {
            return user.text
        }
        return user.id
    }





    $(".gridfilter").each(function(i, filter) {
        var $filter = $(filter),
            filterKey = $filter.data("filter-key"),
            hasFilterUrl = $filter.data("filter-url");

        if(hasFilterUrl){
            filterUrl = hasFilterUrl;
        }

        console.log("utils.gridfilter",i,filterKey,filterUrl);

        $filter.find("input").each(function(j, input) {
            var $input = $(input);


            if($input.hasClass("daterange")){
                return;
                //console.log("daterangepicker found")
                $input.daterangepicker({
                    autoUpdateInput: false,
                    ranges: {
                       'Today': [moment(), moment()],
                       'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                       'Last 3 Days': [moment().subtract(2, 'days'), moment()],
                       'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                       'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                       'Last 90 Days': [moment().subtract(89, 'days'), moment()],
                       'This Month': [moment().startOf('month'), moment().endOf('month')],
                       'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    },
                    locale: {
                        cancelLabel: 'Clear'
                    }
                });

                $input.on('apply.daterangepicker', function(ev, picker) {
                    var val = picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY');
                    //console.log("apply.daterangepicker",val,$input)

                    searchFilter($input.attr("name"),val);

                });

                $input.on('cancel.daterangepicker', function(ev, picker) {
                    searchFilter($input.attr("name"),"");
                });

            }else{
                //console.log("gridfilter.js filter-input",$input.attr('type'),$input.attr('name'))

                if( ($input.attr('type') == "checkbox") && ($input.attr('id') != 'bulk-select-all')){

                    $input.on("change",gridFilterChange)
                }else{
                    $input.on( "keypress", inputKeypress )

                }


            }


        })

        $filter.find("select").each(function(j, input) {
            var $input = $(input)

            $input.on("change",gridFilterChange)


            if($input.hasClass("select2")){
                if($input.find("option").length > 2){
                    // if (! Modernizr.touch) {
                    //     $input.select2();
                    // }

                    $input.select2({

                        placeholder: "Any",
                        allowClear: true
                    });

                }else{

                }
            }
        })


    })


















}); // :)
