

//https://www.codeply.com/p/DBGWVMiKcE#




// document.getElementById('btnSwitch').addEventListener('click',()=>{
//     if (document.body.classList.contains('dark')) {
//         document.body.classList.remove('dark')
//     }
//     else {
//         document.body.classList.add('dark')
//     }
//     document.getElementById('darkTheme').innerText = document.body.classList
// })



$(function(){
// var storedTheme = localStorage.getItem('theme') || (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
// if (storedTheme == "dark"){
//     document.body.classList.add('dark')
// }

    //console.log("nav-theme-dark")

    $(document).on("click","a.site-toggle",function(e){
        e.preventDefault();

        var $this = $(this),
            tk = $this.data("tk"),
            tv = $this.data("tv");

        var refresh = true;
        var data = {
            tk: tk,
            tv: tv,
        }

        $.post("/api/set_toggle",data);
        setTimeout(()=>{
            $.post("/api/set_toggle",data,function(data){
                console.log("set_toggle",data)
                if(refresh){
                    window.location.reload();
                }
            })
        },500)

    })


    $(document).on("click",".nav-theme-dark",function(e){
        e.preventDefault();
        if (document.body.classList.contains('dark')) {
            document.body.classList.remove('dark');
            //localStorage.setItem('theme', 'light');;
            $(".nav-theme-dark").html("<span class='fa fa-lightbulb-o'></span> Light");

            $(".bg-dark, .bg-light").toggleClass('bg-dark').toggleClass('bg-light');

            $.post("/api/set_theme",{bootstrap_theme: "light"});
            setTimeout(()=>{
                $.post("/api/set_theme",{bootstrap_theme: "light"})
            },500)
        }
        else {
            document.body.classList.add('dark');
            //localStorage.setItem('theme', 'dark');;
            $(".nav-theme-dark").html("<span class='fa fa-lightbulb-o'></span> Dark");

            $(".bg-dark, .bg-light").toggleClass('bg-dark').toggleClass('bg-light');

            $.post("/api/set_theme",{bootstrap_theme: "dark"})
            setTimeout(()=>{
                $.post("/api/set_theme",{bootstrap_theme: "dark"})
            },500)
        }
    })




});


