



// $(function(){
//     console.log("popover")
//     var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
//     var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
//       return new bootstrap.Popover(popoverTriggerEl)
//     })

// });


var popoverRemoteContents = function(element) {
  if ($(element).data('loaded') !== true) {
    var div_id = 'tmp-id-' + $.now();
    $.ajax({
      url: $(element).data('popover-remote'),
      success: function(response) {
        $('#' + div_id).html(response);
        $(element).attr("data-html", true);
        $(element).attr("data-loaded", true);
        $(element).attr("data-content", _.escape(response));
        return $(element).popover('update');
      }
    });
    return '<div id="' + div_id + '">Loading...</div>';
  } else {
    return $(_.unescape($(element).data('content')));
  }
};

$(function(){
    $('[data-popover-remote]').popover({
      html: true,
      trigger: 'hover', //focus
      placement: 'top',
      container: 'body',
      //container: $(this),
      content: function() {
        return popoverRemoteContents(this);
      }
    });
});