
import { Dropzone } from "dropzone";


Dropzone.autoDiscover = false

console.log("dropzone.js");


function updateProgress(div,percent){
    div.find(".progress-bar").css("width",percent + "%");
    div.find(".progress-bar").text("Uploading: "+ percent.toFixed() + "%");
    if(percent==0){
        div.show();
    }
    if(percent==100){
        // setTimeout(function(){
        //     div.fadeOut("slow");
        // },3000);
        // //div.fadeOut("slow");
    }
}


$(document).ready(function(){
    $(".dropzone-wrap").each(function(i){

        var $suw = $(this)

        var uploadMultiple = false;
        if ($suw.data("multiple")=="true"){
            uploadMultiple = true;
        }

        console.log("uploadMultiple",uploadMultiple)
        var $input = $suw.find(".dropzone")[0]

        var $progress = $suw.find(".progress")

        var options = {
            // The URL will be changed for each new file being processing
            url: '/',
            // Since we're going to do a `PUT` upload to S3 directly
            method: 'put',

            maxFilesize: 1024, //1gb
            // Hijack the xhr.send since Dropzone always upload file by using formData
            // ref: https://github.com/danialfarid/ng-file-upload/issues/743
            sending (file, xhr) {
              let _send = xhr.send
              xhr.send = () => {
                _send.call(xhr, file)
              }
            },
            // Upload one file at a time since we're using the S3 pre-signed URL scenario
            parallelUploads: 1,
            uploadMultiple: uploadMultiple,
            // Content-Type should be included, otherwise you'll get a signature
            // mismatch error from S3. We're going to update this for each file.
            header: '',
            // Customize the wording
            //dictDefaultMessage: document.querySelector('#dropzone-message').innerHTML,
            // We're going to process each file manually (see `accept` below)
            autoProcessQueue: false,

            // Here we request a signed upload URL when a file being accepted
            accept (file, done) {
                $.getJSON(
                    '/s3_signed_urls/objurl',
                    {
                        filename: file.name,
                        file_type: file.type,
                    },
                    function(data) {
                        upload_image(file,data);
                    }
                );


            },

            processing: function(e,data){
                updateProgress($progress,0);
            },
            uploadprogress: function(e,data){
                //updateProgress($progress,data);
            },
            totaluploadprogress: function(e,data,x){
                // https://github.com/enyo/dropzone/issues/690
                // updateProgress($progress,data);

                var activeFiles = this.getActiveFiles();
                var currentProgress = filesCompleted * 100;
                var remainingProgress = activeFiles.length * 100;
                var maxProgress = remainingProgress + currentProgress;

                if (activeFiles.length) {

                    for(var a = 0; a < activeFiles.length; a++) {
                      currentProgress   = currentProgress + (activeFiles[a].upload.bytesSent / activeFiles[a].size) * 100;
                    }

                    var per = (currentProgress / maxProgress) * 100;

                    if(per > 98){
                        per = 98;
                    }

                    updateProgress($progress,per);

                }
            },
            addedfile: function(file,data) {

            },
            queuecomplete: function() {
                //filesCompleted = 0;
                updateProgress($progress,100);
                //updateAttachmentDisplayOrder();
            }
        }
        // Instantiate Dropzone

        var upload_image = function(file,tmpdat) {
            updateProgress($progress,0);
            $.ajax({
                type : 'PUT',
                url : tmpdat['upload_url'],
                //enctype: 'multipdesign/form-data',
                //contentType: 'binary/octet-stream',
                contentType: file.type,
                data : file,
                processData: false,
                success: function() {
                    var showurl = "/uploads/"+tmpdat['upload_id'];

                    if(window.dropzonecallback){
                        window.dropzonecallback(tmpdat);
                    }

                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                },
                xhr: function() {
                    var xhr = new window.XMLHttpRequest();
                    xhr.upload.addEventListener("progress", function(evt) {
                        if (evt.lengthComputable) {
                            var percentComplete = (evt.loaded / evt.total) * 100;
                            //Do something with upload progress here
                            updateProgress($progress,percentComplete);
                        }
                   }, false);
                   return xhr;
                },

            });
        }


        updateProgress($progress,1);
        updateProgress($progress,0);

        $progress.hide();


        var dropzone = new Dropzone($input, options)





    })

});