

function registerTaggable(){

    // Initialize all acts-as-taggable-on + select2 tag inputs
    $("*[data-taggable='true']").each(function() {
        console.log("Taggable: " + $(this).attr('id') + "; initializing select2");
        $(this).select2({
            tags: true,
            //theme: "bootstrap",
            width: "100%",
            tokenSeparators: [','],
            minimumInputLength: 2,
            ajax: {
                url: "/tags.json",
                dataType: 'json',
                delay: 100,
                data: function (params) {
                    // console.log("Using AJAX to get tags...");
                    // console.log("Tag name: " + params.term);
                    // console.log("Existing tags: " + $(this).val());
                    // console.log("Taggable type: " + $(this).data("taggable-type"));
                    // console.log("Tag context: " + $(this).data("context"));
                    return {
                        name: params.term,
                        tags_chosen: $(this).val(),
                        taggable_type: $(this).data("taggable-type"),
                        context: $(this).data("context"),
                        page: params.page
                    }
                },
                processResults: function (data, params) {
                    //console.log("Got tags from AJAX: " + JSON.stringify(data, null, '\t'));
                    params.page = params.page || 1;

                    return {
                        results: $.map(data.tags, function (item) {
                            return {
                                text: item.name,
                                // id has to be the tag name, because acts_as_taggable expects it!
                                id: item.name
                            }
                        })
                    };
                },
                cache: true
            }
        });
    });
}



$(function(){

    registerTaggable();

    $(document).on('select2:select select2:unselect', "*[data-taggable='true']", function() {
        var taggable_id = $(this).attr('id')
        // genre_list_select2 --> genre_list
        var hidden_id = taggable_id.replace("_select2", "");
        // film_*genre_list* ($= jQuery selectors ends with)
        var hidden = $("[id$=" + hidden_id + "]")
        // Select2 either has elements selected or it doesn't, in which case use []
        var joined = ($(this).val() || []).join(",");
        hidden.val(joined);
    });


    console.log('.btn-tag-toggle');
    //$(document).on('click','.btn-tag-toggle', function(e) {
    $('.btn-tag-toggle').on('click', function(e) {
        e.preventDefault();

        console.log('.btn-tag-toggle');

        var $this = $(this),
            url = new URL($this.attr("href"),window.location.origin),
            state = $this.hasClass('btn-primary');


        url.pathname = url.pathname + ".json"

        console.log(state,url);

        if(state){
            $.ajax({
                type: "DELETE",
                url: url,
                success: function () {
                    $this.removeClass('btn-primary').addClass('btn-outline-primary')
                },
            });
        }else{
            $.ajax({
                type: "POST",
                url: url,
                success: function () {
                    $this.removeClass('btn-outline-primary').addClass('btn-primary')
                },
            });
        }

        return false
    });




});
